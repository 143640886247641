import React from 'react';
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import { WebLinksAddon } from 'xterm-addon-web-links';
import styled from 'styled-components';
import 'xterm/css/xterm.css';
import { lineBreakRegExp } from './application';

const TerminalContainer = styled('div')`
  height: 320px;
  width: 400px;
  @media only screen and (max-width: 600px) {
    width: 335px;
  }
`;

function TerminalComponent() {
  const rootLine = 'immutible $ ';
  const supportedCommands = ['help', 'who', 'contact', 'skills', 'cv', 'clear', 'reset'];
  let command = '';
  const terminal = new Terminal({
    cursorBlink: true,
    theme: {
      background: '#1e1e1e',
    },
  });
  const fitAddon = new FitAddon();
  terminal.loadAddon(fitAddon);
  terminal.loadAddon(new WebLinksAddon());
  if (navigator.userAgent.toLowerCase().includes('android')) {
    terminal.onData((data) => {
      const code: number = data.charCodeAt(0);
      if (code === 13) {
        onKeyHandler(data);
      } else {
        data.split('').forEach((char) => onKeyHandler(char));
      }
    });
  } else {
    terminal.onKey(({ key }) => onKeyHandler(key));
  }
  React.useEffect(() => {
    // code to run after render goes here
    terminal.open(document.getElementById('terminal')!);
    initTerminal();
    fitAddon.fit();
    terminal.focus();
    terminal.element!.addEventListener('click', function () {
      this.scrollIntoView(true);
    });
  });
  const initTerminal = () => {
    terminal.reset();
    terminal.writeln('Welcome stranger');
    renderHelp();
  };
  const renderHelp = () => {
    terminal.writeln('');
    terminal.writeln('Commands: who, contact, skills, cv');
    terminal.writeln('Type command & press enter');
    terminal.writeln('');
    terminal.write(rootLine);
  };
  const onKeyHandler = (key: string) => {
    const code: number = key.charCodeAt(0);
    if (code === 13) {
      // enter
      if (supportedCommands.includes(command)) {
        switch (command) {
          case 'who':
            fetch('./who.txt')
              .then((response) => response.text())
              .then((value) => {
                terminal.writeln('');
                const output = value.replace(lineBreakRegExp, '\r\n');
                terminal.writeln(output);
                terminal.writeln('');
                terminal.write(rootLine);
                command = '';
              });
            return;
          case 'contact':
            fetch('./contact.txt')
              .then((response) => response.text())
              .then((value) => {
                terminal.writeln('');
                const output = value.replace(lineBreakRegExp, '\r\n');
                terminal.writeln(output);
                terminal.writeln('');
                terminal.write(rootLine);
                command = '';
              });
            return;
          case 'skills':
            fetch('./skills.txt')
              .then((response) => response.text())
              .then((value) => {
                terminal.writeln('');
                const output = value.replace(lineBreakRegExp, '\r\n');
                terminal.writeln(output);
                terminal.writeln('');
                terminal.write(rootLine);
                command = '';
              });
            return;
          case 'cv':
            fetch('./cv.txt')
              .then((response) => response.text())
              .then((value) => {
                terminal.writeln('');
                const output = value.replace(lineBreakRegExp, '\r\n');
                terminal.writeln(output);
                terminal.writeln('');
                terminal.write(rootLine);
                command = '';
              });
            return;
          case 'help':
            renderHelp();
            command = '';
            return;
          case 'clear':
          case 'reset':
            terminal.reset();
            terminal.write(rootLine);
            command = '';
            return;
          default:
            terminal.writeln('');
            terminal.writeln('Unsupported command');
            terminal.writeln("Type 'help' to see commands");
            terminal.writeln('');
        }
      } else {
        terminal.writeln('');
        terminal.writeln('Unknown command');
        terminal.writeln("Type 'help' to see commands");
        terminal.writeln('');
      }
      terminal.write(key);
      terminal.write(rootLine);
      command = '';
      return;
    }
    if (code === 127) {
      if (command.length > 0) {
        command = command.slice(0, command.length - 1);
        terminal.write('\b \b');
      }
      return;
    }
    if (code < 32) {
      return;
    }
    terminal.write(key);
    command += key;
  };
  return <TerminalContainer id="terminal"></TerminalContainer>;
}

export default TerminalComponent;
